import axios from '@/axios'
const route = 'budget/service'
export default {

    searchStage(searchStage) {
        return axios.get(route + '/search_stage' + '?search=' + searchStage)
    },

    getAllComposition(ItemsPerPage, page, search) {
        return axios.get(route + '/get_all_composition' + '?page=' + page + '&items_per_page=' + ItemsPerPage + '&search=' + search)
    },

    storeService(request) {
        return axios.post(route, request)
    },

    updateService(request) {
        return axios.put(route, request)
    },

    getServices(budget_id) {
        return axios.get(route + "/" + budget_id)
    },

    deleteService(budgetId, stage_id) {
        return axios.post(route + '/' + budgetId, { stage_id: stage_id })
    },

    getCompositionInputs(composition_id) {
        return axios.get(route + '/edit_composition/get_input/' + composition_id)
    },

    getCompositionCompositions(composition_id) {
        return axios.get(route + '/edit_composition/get_composition/' + composition_id)
    },

    setEditQuantityInput(composition, input) {
        return axios.post(route + '/edit_composition/update_quantity_input/' + composition.id, input)
    },

    setEditValueInput(composition, input) {
        return axios.post(route + '/edit_composition/update_value_input/' + composition.id, input)
    },

    setEditQuantityComposition(editComposition, composition) {
        return axios.post(route + '/edit_composition/update_quantity_composition/' + editComposition.id, composition)
    },

    setUpdateQuantity(costCompositionId, quantity) {
        return axios.post(route + '/update_quantity_service/' + costCompositionId, { quantity: quantity })
    },

    exportExcel(cost_id) {
        return axios.get(route + '/export_excel/'+cost_id,{responseType: 'blob'})
    },

}
